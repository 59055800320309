<template>
  <view-item-setting>
    <template #operation>
 <en-button type="primary">添加模板</en-button>
    </template>
    <tabs-maintain
      :ajax="{
        action: 'GET /enocloud/common/lookup/:lookupType',
        params: (params) => (params.paths = ['RPTTPLTPE'])
      }"
      :props="{ value: 'code', label: 'message' }"
      :method="table.get"
      v-model="tabs.type"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging" :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }">
          <en-table-column header-align="center" label="序号" type="index"></en-table-column>
          <en-table-column header-align="center" label="模板名称" prop="name"></en-table-column>
          <en-table-column header-align="center" label="模板类型" prop="type.message"></en-table-column>
          <en-table-column header-align="center" label="状态" prop="status.message"></en-table-column>
          <en-table-column header-align="center" label="排序" prop="seq"></en-table-column>
          <en-table-column header-align="center" label="备注" prop="comment"></en-table-column>
          <en-table-column header-align="center" label="操作">
            <template #default="{ row }: { row: EnocloudCommonDefinitions['ReportTemplateDto'] }">
              <en-button text type="primary" @click="table.operation.duplicate.click(row)">复制</en-button>
              <en-button text type="primary" @click="table.operation.active.click(row)" v-if="row.status?.code === 'D'">启用</en-button>
              <en-button text type="primary" @click="table.operation.disable.click(row)" v-if="row.status?.code === 'A'">禁用</en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      tabs: {
        type: '',
        readonly: 'Y'
      },
      form: {
        is: 'form',
        data: {
          id: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/report/template',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, ...this.tabs }
            }
          },
          active: {
            action: 'PUT /enocloud/common/report/template/:reportTemplateId/active'
          },
          disable: {
            action: 'PUT /enocloud/common/report/template/:reportTemplateId/disable'
          },
          duplicate: {
            action: 'POST /enocloud/common/report/template/:reportTemplateId/duplicate',
            loading: true
          }
        },
        children: {
          operation: {
            active: {
              click(row: EnocloudCommonDefinitions['ReportTemplateDto']) {
                this.table.active({ paths: [row.id] }).then(this.table.get)
              }
            },
            disable: {
              click(row: EnocloudCommonDefinitions['ReportTemplateDto']) {
                this.table.disable({ paths: [row.id] }).then(this.table.get)
              }
            },
            duplicate: {
              click(row: EnocloudCommonDefinitions['ReportTemplateDto']) {
                this.table.duplicate({ paths: [row.id] })
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
